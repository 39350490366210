<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="6">
        <el-date-picker
          v-model="dateRange"
          type="datetimerange"
          @change="changeDate"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        >
        </el-date-picker>
      </el-col>

      <el-col :span="4">
        <el-select
          v-model="query.type"
          placeholder="订单类型"
          :maxLength="50"
          clearable
        >
          <el-option
            v-for="(item, index) in this.const.REFUND_TYPE"
            :value="item.value"
            :label="item.name"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <div><el-button type="primary" @click="exportData">导出</el-button></div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column label="退款单号" prop="outRefundNo"> </el-table-column>

      <el-table-column label="姓名" prop="trueName"> </el-table-column>

      <el-table-column label="金额" prop="amount" align="right">
        <template #default="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.RETURN_DEPOSIT_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="原因" prop="reason"> </el-table-column>

      <el-table-column label="时间 " prop="createAt"> </el-table-column>
    </el-table>
    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>


<script>
import { orderRefundList, exportOrderRefund } from "@/api/order";
import moment from "moment";

export default {
  data() {
    let startTime = moment()
      .startOf("day")
      .subtract(1, "month")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("day").format("YYYY-MM-DD HH:mm:ss");
    return {
      dateRange: [startTime, endTime],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      dataSource: [],
      query: {
        startTime: startTime,
        endTime: endTime,
      },
      modalType: "add",
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.query.startTime = moment(e[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.query.endTime = moment(e[1])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        this.dateRange = [this.query.startTime, this.query.endTime];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
    },

    exportData() {
      let queryData = this.util.deepClone(this.query);
      exportOrderRefund(queryData).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${queryData.startTime}-${queryData.endTime}退款订单.xlsx`
        );
      });
    },
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      orderRefundList({ ...this.query, ...this.pages }).then((res) => {
        let list = res.data.data.records || [];
        this.total = res.data.data.total;
        this.dataSource = list;
      });
    },
  },
};
</script>
